import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import { _notifications } from 'src/_mock';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import NotificationItem from './notification-item';
import { useQueryNotification, useQueryRemiderList } from 'src/api/mortgage';
import { useLocales } from 'src/locales';
import { Types } from 'src/generated/types';
import moment from 'moment';
import { ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'all',
    label: 'All',
    count: '',
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const { t } = useLocales();
  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');
  const [notifications, setNotifications] = useState<Types.Customer.RemiderItem[] | null>([]);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const {
    isLoading,
    data: totalBadgeNotification,
    refetch: onRefectNotification,
  } = useQueryNotification(
    { is_active: 1 },
    {
      loadFirst: true,
      onSuccess: (r) => {},
      onError: (er) => {
        console.log(er);
      },
    }
  );

  const {
    isLoading: isLoadingReminder,
    data: reminderData,
    refetch: onRefectReminder,
  } = useQueryRemiderList(
    {
      sort: 'DESC',
      limit: '50',
      is_active: 1,
    },
    {
      loadFirst: true,
      onSuccess: ({ data, message }) => {
        setNotifications(data?.data || []);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  const checkPaymentDay = (_reminder: string, _delay_day: string) => {
    const today = moment().format('YYYY-MM-DD');
    const withDelayDate = moment(_reminder).add(Number(_delay_day), 'days').format('YYYY-MM-DD');

    if (moment(today).isSameOrAfter(withDelayDate)) {
      return true;
    } else if (moment(_reminder).isSameOrBefore(today)) {
      return false;
    }
    return false;
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('notifications')}
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={'error'}
            >
              {totalBadgeNotification?.data || 0}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderEmpty = (
    <ListItemButton
      disableRipple
      sx={{
        p: 1,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} py={5}>
        <Iconify icon={'ic:outline-notifications-off'} width={'40px'} />
        <Typography fontSize={'small'}>No notifactions lists</Typography>
      </Stack>
    </ListItemButton>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.map((notification) => {
          return (
            <NotificationItem
              key={notification.id + ''}
              notification={notification}
              closeDrawer={() => {
                drawer.onFalse();
              }}
              isNotPay={checkPaymentDay(
                notification?.reminder_at?.toString() || '',
                notification?.mortgage?.delay_day?.toString() || ''
              )}
            />
          );
        })}
      </List>
    </Scrollbar>
  );

  useEffect(() => {
    //refetch in 10 minute
    const timeRefetch = 600;
    const interval = setInterval(() => {
      onRefectNotification();
      onRefectReminder();
    }, timeRefetch * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalBadgeNotification?.data} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>
        <Divider />
        {notifications && notifications?.length > 0 ? renderList : renderEmpty}
      </Drawer>
    </>
  );
}
