import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({ baseURL: HOST_API, withCredentials: true });

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // config.headers['Content-Type'] = 'multipart/form-data';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      error.response
        ? { ...error.response.data, status: error?.response?.status }
        : 'Something went wrong'
    )
);

export const axiosPut = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.put(URL, arg);
  return res.data;
};

export const axiosPost = async (url: string, { arg }: { arg: any }) => {
  const res = await axiosInstance.post(url, arg);
  return res.data;
};

export const axiosDelete = async (url: string, { arg }: { arg: any }) => {
  const id = arg.query && arg.query.id ? arg.query.id : '';
  const URL = `${url}${!!id ? '/' + id : ''}`;
  const res = await axiosInstance.delete(URL, { data: arg });
  return res.data;
};

export const axiosMutate = (method?: any) => {
  if (method === 'PUT') {
    return axiosPut;
  } else if (method === 'DELETE') {
    return axiosDelete;
  }
  return axiosPost;
};

export const axiosQuery = async (url: string, { arg }: { arg: any }) => {
  const query = Object.entries(arg || {})
    .filter((f) => f[1] != null && f[1] != undefined)
    .map((x) => `${x[0]}=${x[1]}`)
    .join('&');
  const URL = `${url}${!!query ? '?' : ''}${query}`;
  const res = await axiosInstance.get(URL);
  return res.data;
};

export default axiosInstance;
