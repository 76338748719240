import { update } from 'lodash';

export default {
  csrf: '/sanctum/csrf-cookie',
  auth: {
    me: '/api/me',
    login: '/api/login',
    logout: '/api/logout',
  },
  customer: {
    root: (id?: any) => `/api/customer/${String(id)}`,
    update: (id?: any) => `/api/customer/${String(id)}`,
    list: '/api/customer',
    create: '/api/customer',
    upload: 'api/image/upload',
  },
  mortgage: {
    root: (id?: any) => `/api/mortgage/${String(id)}`,
    list: '/api/mortgage',
    create: '/api/mortgage',
    type: '/api/mortgage/type',
    payment: (id?: any) => `/api/mortgage/payment/${String(id)}`,
    update: (id?: any) => `/api/mortgage/${String(id)}`,
    reminder: '/api/mortgage/reminder',
    notification: '/api/mortgage/notification',
  },
  transaction: {
    list: '/api/transaction',
    transfer: 'api/transaction/transfer',
    expend: 'api/transaction/expend',
  },
  user: {
    root: (id?: any) => `/api/user/${String(id)}`,
    update: (id?: any) => `/api/user/${String(id)}`,
    list: '/api/user',
    create: '/api/user',
  },
  branch: {
    root: (id?: any) => `/api/branch/${String(id)}`,
    update: (id?: any) => `/api/branch/${String(id)}`,
    list: '/api/branch',
    create: '/api/branch',
  },
};
