import { SWROptions, useMutateSWR, useQuerySWR } from 'src/utils/swr-instanct';
import endpoints from './endpoints';
import { Types } from 'src/generated/types';

export interface MortgageNotificationParams {
  is_active?: number;
}

export function useQueryNotification(
  params: MortgageNotificationParams,
  options?: SWROptions<any, MortgageNotificationParams>
) {
  const res = useQuerySWR(endpoints.mortgage.notification, params, options);
  return res;
}

export interface MortgageReminderParams {
  sort?: null | string;
  limit?: null | string;
  is_active?: number;
  mortgage_type?: string | null;
}

export function useQueryRemiderList(
  params: MortgageReminderParams,
  options?: SWROptions<Types.Customer.ReminderList, MortgageReminderParams>
) {
  const res = useQuerySWR(endpoints.mortgage.reminder, params, options);
  return res;
}

export interface MortgageListParams {
  sort?: null | string;
  limit?: null | string;
  branch_id?: string | null;
  status?: string | null;
  mortgage_type?: string | null;
  customer_id?: string | null;
  search_id?: string | null;
  search_id_card_name?: string | null;
  filter_date?: Types.FilterDate;
  start_at?: string | null;
  end_at?: string | null;
}

export function useQueryMortgageList(
  params: MortgageListParams,
  options?: SWROptions<Types.Customer.MortgageList, MortgageListParams>
) {
  const res = useQuerySWR(endpoints.mortgage.list, params, options);
  return res;
}

export function useQueryMortgageDetail(
  id?: string,
  options?: SWROptions<Types.Customer.MortgageItem, any>
) {
  const res = useQuerySWR(endpoints.mortgage.root(id), undefined, options);
  return res;
}

export interface MortgageCreateParams {
  customer_id: number | null;
  mortgage_type: string | null;
  interest_rate: number | null;
  penalty_rate: number | null;
  pawn_amount: number | null;
  redeem_amount: number | null;
  interest_amount: number | null;
  penalty_amount: number | null;
  delay_day: number | null;
  start_at: string | null;
  end_at: string | null;
  reminder_at: string | null;
  expired_at: string | null;
}

export function useCreateMortgage(
  params?: MortgageCreateParams,
  options?: SWROptions<Types.Customer.MortgageItem, MortgageCreateParams>
) {
  const res = useMutateSWR(endpoints.mortgage.create, params, options);
  return res;
}

export interface MortgageUpdateParams {
  // customer_id: number | null;
  mortgage_type: string | null;
  interest_rate: number | null;
  penalty_rate: number | null;
  pawn_amount: number | null;
  // redeem_amount: number | null;
  // interest_amount: number | null;
  // penalty_amount: number | null;
  delay_day: number | null;
  start_at: string | null;
  end_at: string | null;
  reminder_at: string | null;
  expired_at: string | null;
}

export function useUpdateMortgage(
  id?: string,
  params?: MortgageUpdateParams,
  options?: SWROptions<Types.Customer.MortgageItem, MortgageUpdateParams>
) {
  const res = useMutateSWR(endpoints.mortgage.update(id), params, { ...options, method: 'PUT' });
  return res;
}

export interface CreatePaymentParams {
  amount: number | null;
  password: string | null;
}

export function useCreatePayment(
  id: string,
  params?: CreatePaymentParams,
  options?: SWROptions<any, CreatePaymentParams>
) {
  const res = useMutateSWR(endpoints.mortgage.payment(String(id)), params, options);
  return res;
}

export interface MortgageListParams {
  sort?: string | null;
  limit?: string | null;
  search_en_name?: string | null;
  search_kh_name?: string | null;
  is_active?: number;
}

export function useQueryMortgageType(
  params?: MortgageListParams,
  options?: SWROptions<Types.Customer.MortgageTypeList, MortgageListParams>
) {
  const res = useQuerySWR(endpoints.mortgage.type, params, options);
  return res;
}
