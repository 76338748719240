import { createRef } from 'react';
import { AlertPopupProps, AlertPopupRef } from 'src/components/alert/alert-popup';

export const ERROR_SOMETHING_WENT_WRONG = 'មានភាពមិនប្រក្រតីកើតឡើង។';
export const alertPopupRef = createRef<AlertPopupRef>();

export function alertSuccess(params: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'SUCCESS',
    });
}

export function alertError(params: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'ERROR',
    });
}

export function alertUnknown(params?: AlertPopupProps) {
  alertPopupRef.current &&
    alertPopupRef.current.show({
      ...params,
      status: 'ERROR',
      message: ERROR_SOMETHING_WENT_WRONG,
    });
}
