import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
//CUSTOMER
const CustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/list'));
const CustomerDetailPage = lazy(() => import('src/pages/dashboard/loan-customer/detail'));
const CreateCustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/create'));
const EditCustomerPage = lazy(() => import('src/pages/dashboard/loan-customer/edit'));

//MORTGAGE
const MortgagePage = lazy(() => import('src/pages/dashboard/mortgage/list'));
const EditMortgagePage = lazy(() => import('src/pages/dashboard/mortgage/edit'));
const UpdateMortgagePage = lazy(() => import('src/pages/dashboard/mortgage/detail'));
const MortgageCreatePage = lazy(() => import('src/pages/dashboard/mortgage/create'));

//TRANSACTION
const TransactionListPage = lazy(() => import('src/pages/dashboard/transaction/list'));

//USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const CreateUserPage = lazy(() => import('src/pages/dashboard/user/create'));
const UserDetailPage = lazy(() => import('src/pages/dashboard/user/detail'));
const UserUpdatePage = lazy(() => import('src/pages/dashboard/user/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },

      {
        path: 'customer',
        children: [
          { element: <CustomerPage />, index: true },
          { path: 'create', element: <CreateCustomerPage /> },
          { path: 'edit/:id', element: <EditCustomerPage /> },
          { path: ':id', element: <CustomerDetailPage /> },
        ],
      },
      {
        path: 'mortgage',
        children: [
          { element: <MortgagePage />, index: true },
          { path: 'create', element: <MortgageCreatePage /> },
          { path: 'edit/:id', element: <EditMortgagePage /> },
          { path: 'detail/:id', element: <UpdateMortgagePage /> },
        ],
      },
      {
        path: 'transaction',
        children: [
          { element: <MortgagePage />, index: true },
          { path: 'list', element: <TransactionListPage /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'create', element: <CreateUserPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'detail/:id', element: <UserDetailPage /> },
          { path: 'edit/:id', element: <UserUpdatePage /> },
        ],
      },
    ],
  },
];
