export function formatUrl(_url: string | null) {
  const domain = process.env.REACT_APP_HOST_API;
  if (_url) {
    return domain?.substring(0, domain.length - 1) + _url;
  } else {
    return '';
  }
}

export function khCurrency(currency: any) {
  if (!!currency) {
    return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return currency;
}
