// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { Types } from 'src/generated/types';
import { useLocales } from 'src/locales';
import { Box, Card, Divider } from '@mui/material';
import { khCurrency } from 'src/utils/format-img-url';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';

interface Props {
  isNotPay?: boolean;
  closeDrawer?: () => void;
  notification: Types.Customer.RemiderItem;
}

export default function NotificationItem({ closeDrawer, notification, isNotPay }: Props) {
  const { t } = useLocales();
  const router = useRouter();

  const renderBadge = (
    <Stack
      bgcolor={isNotPay ? '#ff2f30' : '#ffab00'}
      width={14}
      height={14}
      position={'absolute'}
      right={6}
      top={6}
      borderRadius={'8px'}
      zIndex={100}
    ></Stack>
  );

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 1,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderBadge}
      <Card
        onClick={() => {
          closeDrawer && closeDrawer();
          notification?.mortgage?.id &&
            router?.push(
              `${paths.dashboard.mortgage.detail(notification?.mortgage?.id?.toString())}`
            );
        }}
        sx={{
          p: 1,
          bgcolor: isNotPay ? '#ffd5d5' : '#ffecd5',
          width: '100%',
          typography: 'h4',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <Stack
          borderRadius={0}
          direction="row"
          divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
        >
          <Stack width={1}>
            <Typography
              fontSize="small"
              textAlign={'left'}
              px={1}
              variant="body2"
              fontWeight={'bold'}
            >
              {notification.customer?.name}
            </Typography>
            <Typography fontSize="small" textAlign={'left'} px={1}>
              {notification.customer?.contact}
            </Typography>
            <Typography fontSize="small" textAlign={'left'} px={1}>
              {t('reminder_at')}: {notification.reminder_at}
            </Typography>
          </Stack>
          <Stack width={1}>
            {khCurrency(Number(notification.redeem_amount) + Number(notification.interest_amount))}
            <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
              {t('pay_amount')}
            </Box>
          </Stack>
        </Stack>
      </Card>
    </ListItemButton>
  );
}
