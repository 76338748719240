import { Alert, Button, Dialog, Modal, Stack } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';

export interface AlertPopupProps {
  message?: string;
  title?: string;
  status?: 'SUCCESS' | 'ERROR';
  onClick?: () => void;
}

export interface AlertPopupRef {
  show: (props: AlertPopupProps) => void;
}

const AlertPopup = forwardRef((_, ref?: any) => {
  const [msg, setMsg] = useState<AlertPopupProps | null>(null);

  const onClose = (e: MouseEvent | any) => {
    e.preventDefault();
    e.stopPropagation();
    msg && msg.onClick && msg.onClick();
    setMsg(null);
  };

  const show = (_msg: AlertPopupProps) => {
    setMsg(_msg);
  };

  useImperativeHandle(ref, () => ({ show }));

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={!!msg}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <Alert
        variant="filled"
        severity={msg?.status === 'SUCCESS' ? 'success' : 'error'}
        action={
          <Button onClick={onClose} variant="contained" color="primary">
            {' '}
            {msg?.title || 'យល់ព្រម'}
          </Button>
        }
      >
        {msg?.message}
      </Alert>

      {/* <Stack sx={{ flexFlow: 1, alignItems: 'center', justifyContent: 'center', p: 2 }}>
        <div className="bg-white p-2 rounded-lg w-full max-w-md relative border-5 border:bg-slate-100 shake">
          <div className="mb-2 items-center justify-center w-full min-h-[6rem] max-h-52 overflow-auto bg-gray-50 rounded">
            <label className="text-center text-sm md:text-base text-[#333] font-battambang">
              {msg?.message}
            </label>
          </div>
          <button
            autoFocus
            onClick={onClose}
            onSubmit={onClose}
            className={`text-white rounded-md py-1.5 text-sm md:text-base font-battambang ${
              msg?.status === 'ERROR' ? 'bg-error' : 'bg-success'
            }`}
          >
            {msg?.title || 'យល់ព្រម'}
          </button>
        </div>
      </Stack> */}
    </Dialog>
  );
});

export default AlertPopup;
