import { ListItemButton, MenuItem, Stack, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { useLocales } from 'src/locales';
import { khCurrency } from 'src/utils/format-img-url';

export default function ProfileNav() {
  const { user } = useAuthContext();
  const { t } = useLocales();
  return (
    <Stack display={'flex'} flexDirection={'row'} gap={2}>
      {/* <Typography fontSize={'small'} fontWeight={'bold'}>
        {user?.name}
      </Typography> */}
      <Typography fontSize={'medium'} fontWeight={'bold'}>
        {t(`balance`)}:{' '}
        <span style={{ color: '#ff5631' }}>{khCurrency(user?.credit?.balance)}</span>
      </Typography>
    </Stack>
  );
}
